@media screen and (min-width: 768px) and (max-width: 1200px),
  screen and (max-width: 896px) and (min-aspect-ratio: 13/9) {
  .HeaderSection {
    overflow-x: hidden;

    height: 778px;
  }

  .HeaderSection_welcomeSection {
    overflow: hidden;
  }

  .HeaderMenu_menuContainer__open {
    height: 100vh;
  }

  .HeaderSection_main {
    align-items: flex-start;
  }

  .HeaderSection_container {
    padding-top: 180px;
  }
  .HeaderSection_buttons {
    margin-top: 20px;
  }

  .HeaderSection_welcome {
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-right: 0;
  }

  .HeaderSection_title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    text-align: center;
  }

  .HeaderSection_description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    text-align: center;
  }

  .HeaderSection_triangle {
    clip-path: polygon(265% 1%, 0% 55%, 100% 100%);
  }

  .HeaderSection_overflow {
    right: -20%;
    bottom: initial;
    left: initial;

    width: calc(100vh * 16 / 9);
  }
}
