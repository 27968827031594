@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .HeaderMenu_burgerBlock {
    display: initial;
  }

  .HeaderMenu_logo svg {
    width: 94px;
  }

  .HeaderSection_menu {
    box-sizing: content-box;
    height: 32px;
    min-height: 32px;
    margin: 20px 16px;
  }

  .HeaderMenu_overflow {
    z-index: -1;
    width: 177.778vh;
    height: 100vh;
    pointer-events: none;
    touch-action: none;
    position: absolute;
  }

  .HeaderMenu_menu {
    display: none !important;
  }

  .HeaderMenu_menu__mobile {
    z-index: var(--z-index-menu);
    height: 56px;
    color: var(--bright);
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  .HeaderMenu_menuContainer__open.HeaderMenu_menu__mobile {
    height: calc(100vh + (var(--vh-px, 0)  - 100vh) );
  }

  .HeaderMenu_menuContainer {
    width: 100%;
    height: 32px;
    justify-content: space-between;
    margin: 0;
    padding: 28px 48px;
    display: flex;
  }

  .HeaderMenu_menuContainer__open {
    background: var(--gradient-main);
    position: fixed;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    display: flex;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_overflow {
    display: initial;
  }

  .HeaderMenu_links, .HeaderMenu_initial, .HeaderMenu_entryIconDark, .HeaderMenu_logoDark {
    display: none;
  }

  .HeaderMenu_burgerBlock {
    position: relative;
  }

  .HeaderMenu_burgerBlock, .HeaderMenu_burgerBlock input {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    border: none;
    outline: none;
  }

  .HeaderMenu_burgerBlock input {
    z-index: -999;
    opacity: 0;
    margin: 0;
  }

  .HeaderMenu_burger {
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 1px;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .HeaderMenu_burger span {
    min-width: 100%;
    height: 2.5px;
    background-color: var(--bright);
    border-radius: 8px;
    transition: all .3s;
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:first-child {
    transform: rotate(-45deg)translate(-7px, 8px);
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:nth-child(2) {
    opacity: 0;
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:last-child {
    transform: rotate(45deg)translate(-5px, -7px);
  }

  .HeaderMenu_linksBlockMobile {
    width: 100vw;
    height: calc(100vh + (var(--vh-px, 0)  - 100vh)  - 50px);
    min-height: 512px;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
  }

  .HeaderMenu_linksMobile {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px;
    display: flex;
  }

  .HeaderMenu_linkMobile {
    width: fit-content;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }

  .HeaderMenu_linkMobile.HeaderMenu_linkMobile__active:after {
    content: "";
    width: 100%;
    height: 3px;
    background: #fff;
    border-radius: 8px;
    display: inline-block;
  }

  .HeaderMenu_linkMobile:last-child {
    margin-bottom: 0;
  }

  .HeaderMenu_buttonsMobile {
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    display: flex;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    padding: 0 48px;
  }

  .HeaderMenu_menu__mobileSticky {
    position: fixed;
  }

  .HeaderMenu_menu__mobileSticky.HeaderMenu_menu__mobile {
    background-color: var(--bright);
    box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_burger span {
    background-color: var(--primary);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_logoLight path {
    fill: var(--primary);
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) and (max-height: 812px) {
  .HeaderMenu_linksBlockMobile {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .HeaderSection {
    height: 778px;
    overflow-x: hidden;
  }

  .HeaderSection_welcomeSection {
    overflow: hidden;
  }

  .HeaderMenu_menuContainer__open {
    height: 100vh;
  }

  .HeaderSection_main {
    align-items: flex-start;
  }

  .HeaderSection_container {
    padding-top: 180px;
  }

  .HeaderSection_buttons {
    margin-top: 20px;
  }

  .HeaderSection_welcome {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }

  .HeaderSection_title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  .HeaderSection_description {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .HeaderSection_triangle {
    -webkit-clip-path: polygon(265% 1%, 0% 55%, 100% 100%);
    clip-path: polygon(265% 1%, 0% 55%, 100% 100%);
  }

  .HeaderSection_overflow {
    right: -20%;
    bottom: initial;
    left: initial;
    width: 177.778vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .BenefitsSection {
    box-sizing: content-box;
    height: 360px;
    padding: calc(50vh - 180px) 0;
    position: relative;
  }

  .BenefitsSection_container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
    display: flex;
  }

  .BenefitsSection_title {
    text-align: left;
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .BenefitsSection_container h2 {
    text-align: left;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .BenefitsSection_slideContainer {
    width: 396px;
    min-width: 396px;
  }

  .BenefitsSection_viewSlider {
    width: 416px;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
    margin-left: -15px;
    padding: 0 20px;
    display: flex;
  }

  .BenefitsSection_shadow {
    box-shadow: 0 0 10px 25px var(--bright);
  }

  .BenefitsSection_slide {
    --position: 100vw;
    width: 100%;
    min-width: initial;
    -webkit-transform: translateX(var(--position) );
    -moz-transform: translateX(var(--position) );
    -ms-transform: translateX(var(--position) );
    -o-transform: translateX(var(--position) );
    transform: translateX(var(--position) );
    padding: 0;
  }

  .BenefitsSection_slide__shown {
    --position: 0;
  }

  .BenefitsSection_slideImg {
    width: 32px;
    height: 32px;
  }

  .BenefitsSection_dots {
    justify-content: flex-start;
    margin-top: 18px;
    display: flex;
  }

  .BenefitsSection_dot {
    margin: 0 5px;
  }

  .BenefitsSection_slide span, .BenefitsSection_slideContainer .__subtitle-1 {
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) and (max-height: 600px) {
  .BenefitsSection {
    padding-top: 52px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .ForEmployersSection {
    overflow: initial;
    height: initial;
    height: 962px;
    justify-content: center;
  }

  .ForEmployersSection_steps {
    width: 100%;
    height: 100%;
  }

  .ForEmployersSection_title {
    text-align: center;
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .ForEmployersSection_container h2 {
    text-align: center;
    margin-top: 9px;
    margin-bottom: 27px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .ForEmployersSection_container .__subtitle-1 {
    text-align: center;
    margin-bottom: 17px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .ForEmployersSection_description {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .ForEmployersSection_chart {
    width: 142px;
    top: -62px;
    left: -4px;
  }

  .ForEmployersSection_step:last-child {
    margin-bottom: 0;
  }

  .ForEmployersSection_step__first {
    bottom: initial;
    left: initial;
    margin-top: var(--section-margin-top);
  }

  .ForEmployersSection_step__second {
    bottom: initial;
    left: initial;
    align-self: center;
  }

  .ForEmployersSection_step__third {
    top: initial;
    right: initial;
    align-self: flex-end;
  }

  .ForEmployersSection_steps {
    flex-direction: column;
    display: flex;
  }

  .ForEmployersSection_arrow__first {
    top: initial;
    bottom: -32px;
    right: 10px;
    transform: rotate(103deg);
  }

  .ForEmployersSection_arrow__second {
    top: initial;
    bottom: -32px;
    right: 5px;
    transform: scale(1, -1)rotate(285deg);
  }

  .ForEmployersSection_container {
    width: 100vw;
    place-self: initial;
    padding: 48px;
  }

  .ForEmployersSection_step {
    box-sizing: content-box;
    width: 338px;
    height: initial;
    margin-top: 84px;
    margin-bottom: 67px;
    padding: 49px 30px 45px;
    position: relative;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .EtzMobileSection {
    box-sizing: content-box;
    height: 746px;
    justify-content: flex-start;
    margin-top: 120px;
    margin-bottom: 0;
    padding: 0 16px 120px;
  }

  .EtzMobileSection_block {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    place-self: initial;
    flex-direction: column;
  }

  .EtzMobileSection_description {
    z-index: 50;
    min-width: initial;
  }

  .EtzMobileSection_title {
    text-align: center;
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .EtzMobileSection_subTitle {
    width: 100%;
    text-align: center;
    margin: 8px 0 32px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .EtzMobileSection_textContainer {
    text-align: center;
    padding: 0;
  }

  .EtzMobileSection_text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .EtzMobileSection_mobiles {
    height: 508px;
    transform: translateX(25%);
  }

  .EtzMobileSection_img {
    height: 622px;
  }

  .EtzMobileSection_eth {
    right: 124px;
    bottom: calc(-91px - var(--coin-position) );
  }

  .EtzMobileSection_btc {
    top: calc(156px + var(--coin-position) );
    left: 99px;
  }

  .EtzMobileSection_ada {
    top: calc(292px + var(--coin-position) );
    left: -121px;
  }

  .EtzMobileSection_avax {
    top: calc(196px + var(--coin-position) );
    left: 266px;
  }

  .EtzMobileSection_bnb {
    top: calc(51px + var(--coin-position) );
    left: -109px;
  }

  .EtzMobileSection_btc {
    top: calc(59px + var(--coin-position) );
    left: 102px;
  }

  .EtzMobileSection_ftm {
    top: calc(2px + var(--coin-position) );
    left: -155px;
  }

  .EtzMobileSection_sol {
    top: calc(-14px + var(--coin-position) );
    right: 105px;
  }

  .EtzMobileSection_usdc {
    bottom: calc(-178px - var(--coin-position) );
    left: 125px;
  }

  .EtzMobileSection_pattern {
    transform: initial;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .SupportedCryptoSection {
    height: 100%;
    margin-top: 0;
    padding: 0;
  }

  .SupportedCryptoSection_title {
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .SupportedCryptoSection_subtitle {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .SupportedCryptoSection_container {
    width: calc(100vw - 32px);
    align-items: center;
    place-self: initial;
    flex-direction: column;
    padding: 0 48px;
    display: flex;
  }

  .SupportedCryptoSection_table {
    width: calc(100vw - 32px);
    height: 532px;
    background: var(--primary-05);
    border-radius: 20px;
    flex-flow: column wrap;
    margin-top: 40px;
    padding: 23px 8px;
    display: flex;
  }

  .SupportedCryptoSection_item {
    margin: 8px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .SupportedCryptoSection_numberCoin {
    min-width: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .FAQSection {
    min-height: 100%;
    margin-top: 120px;
    padding: 0;
  }

  .FAQSection_accordionBlock {
    width: 100%;
    padding: 0 16px;
  }

  .FAQSection_title {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .FAQSection_subtitle {
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .FAQSection_container {
    width: calc(100vw - 48px);
    place-self: initial;
    padding: 0 48px;
  }

  .FAQSection_accordion {
    margin-bottom: 16px;
  }

  .FAQSection_accordionSummary {
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .FAQSection_accordionDetailsDescription {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .FAQSection_accordionColumns {
    -webkit-column-count: 1;
    -moz-column-count: 2;
    column-count: 1;
    -webkit-column-gap: 64px;
    -moz-column-gap: 64px;
    column-gap: 64px;
  }

  .FAQSection_accordionDetailsItem__50 {
    flex-basis: initial;
    flex-grow: initial;
    min-width: initial;
    max-width: initial;
    margin-right: initial;
  }

  .FAQSection_accordionDetailsTitle {
    align-self: stretch;
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .FAQSection_accordionDetailsDescription {
    flex: none;
    order: 1;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .FAQSection_accordionSummary {
    padding: 21px 16px;
  }

  .FAQSection_accordionDetails {
    padding: 0 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .ContactUsSection {
    min-height: 740px;
    margin-top: 0;
    padding: 0;
  }

  .ContactUsSection_container {
    width: 100%;
    place-self: initial;
    padding: 0 16px;
  }

  .ContactUsSection_card {
    width: 100%;
    height: initial;
    padding: 48px 32px;
  }

  .ContactUsSection_peopleImgBlock {
    display: none;
  }

  .ContactUsSection_input {
    width: 100%;
  }

  .ContactUsSection_input__left {
    margin-right: 8px;
  }

  .ContactUsSection_input__right {
    margin-left: 0;
  }

  .ContactUsSection_input__half, .ContactUsSection_input__3Quarters, .ContactUsSection_input__1Quarters {
    min-width: calc(50% - 4px);
    flex-grow: 0;
    flex-basis: 265px;
    margin-bottom: 16px;
  }

  .ContactUsSection_formBlock {
    flex-basis: initial;
    flex-grow: initial;
    max-width: initial;
  }

  .ContactUsSection_form {
    max-width: inherit;
  }

  .ContactUsSection_title {
    text-align: left;
    margin-bottom: 28px;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .ContactUsSection_subtitle {
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .ContactUsSection_textarea {
    width: 100%;
    height: 90px;
  }

  .ContactUsSection_formFooter {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    display: flex;
  }

  .ContactUsSection_formButtonBlock {
    align-self: center;
  }

  .ContactUsSection_submit {
    width: 180px;
    height: 56px;
  }

  .Button_text {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .ContactUsSection_container {
    width: 100%;
    place-self: initial;
    padding: 48px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .Footer {
    align-items: initial;
    margin-top: 50px;
  }

  .Footer_nav {
    width: 100%;
    flex-direction: column;
    padding: 40px 16px 32px;
  }

  .Footer_nav a {
    margin-right: 0;
  }

  .Footer_nav .Footer_logoLink {
    width: 100px;
    margin-bottom: 34px;
    margin-right: 0;
  }

  .Footer_linksMobile span {
    float: left;
    width: 50%;
    cursor: pointer;
    margin: 0 0 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .Footer_links a {
    float: left;
    margin: 0 0 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .Footer_privacy {
    width: initial;
    color: var(--dark-50);
    margin: 32px 16px 19px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .Footer_copyright {
    margin: 0;
  }

  .Footer_address {
    width: initial;
    margin: 0 48px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .Footer_descriptionBlock {
    width: 100%;
    flex-flow: column wrap;
    padding: 0 16px 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .Footer_checkbox {
    width: 60px;
  }

  .Footer_nav {
    padding: 40px 48px 32px;
  }

  .Footer_privacy {
    margin: 32px 48px 19px;
  }

  .Footer_descriptionBlock {
    padding: 0 48px 40px;
  }

  .Footer_links {
    justify-content: space-between;
    display: flex;
  }

  .Footer_nav .Footer_logoLink svg {
    width: 70px;
  }
}

@media screen and (max-width: 768px), (max-width: 1200px), screen and (max-width: 896px) and (min-aspect-ratio: 13 / 9) {
  .Notification {
    max-width: 100vw;
    padding: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .Notification_messageContainer {
    max-width: calc(100vw - 32px);
    padding: 14px 14px 14px 24px;
  }

  .Notification_exit {
    width: initial;
    min-width: 14px;
    height: initial;
    min-height: 14px;
    margin: 0;
    padding: 5px 5px 10px 15px;
  }
}





/*# sourceMappingURL=index.83b9fe8e.css.map */
