@media screen and (max-width: 768px),
  (max-width: 1200px),
  screen and (max-width: 896px) and (min-aspect-ratio: 13/9) {
  .Notification {
    right: 0;
    bottom: 0;
    left: 0;

    max-width: 100vw;
    padding: 0;
  }

  .Notification_messageContainer {
    max-width: calc(100vw - 32px);
    padding: 14px 14px 14px 24px;
  }

  .Notification_exit {
    width: initial;
    min-width: 14px;
    height: initial;
    min-height: 14px;
    margin: 0;
    padding: 5px 5px 10px 15px;
  }
}
