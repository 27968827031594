@media screen and (min-width: 768px) and (max-width: 1200px),
  screen and (max-width: 896px) and (min-aspect-ratio: 13/9) {
  .HeaderMenu_burgerBlock {
    display: initial;
  }

  .HeaderMenu_logo svg {
    width: 94px;
  }

  .HeaderSection_menu {
    box-sizing: content-box;
    height: 32px;
    min-height: 32px;
    margin: 20px 16px;
  }

  .HeaderMenu_overflow {
    position: absolute;
    z-index: -1;

    width: calc(100vh * 16 / 9);
    height: 100vh;

    pointer-events: none;

    touch-action: none;
  }

  .HeaderMenu_menu {
    display: none !important;
  }

  .HeaderMenu_menu__mobile {
    position: absolute;
    z-index: var(--z-index-menu);

    display: flex;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;

    height: 56px;

    color: var(--bright);

    inset: 0;
  }

  .HeaderMenu_menuContainer__open.HeaderMenu_menu__mobile {
    height: calc(100vh + (var(--vh-px, 0) - 100vh));
  }

  .HeaderMenu_menuContainer {
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 32px;
    margin: 0;
    padding: 28px 48px;
  }

  .HeaderMenu_menuContainer__open {
    position: fixed;

    background: var(--gradient-main);
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    display: flex;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_overflow {
    display: initial;
  }

  .HeaderMenu_links {
    display: none;
  }

  .HeaderMenu_initial {
    display: none;
  }

  .HeaderMenu_entryIconDark {
    display: none;
  }

  .HeaderMenu_logoDark {
    display: none;
  }

  .HeaderMenu_burgerBlock {
    position: relative;
  }

  .HeaderMenu_burgerBlock,
  .HeaderMenu_burgerBlock input {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;

    border: none;
    outline: none;
  }

  .HeaderMenu_burgerBlock input {
    z-index: -999;

    margin: 0;

    opacity: 0;
  }

  .HeaderMenu_burger {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 5px 1px;

    cursor: pointer;
  }

  .HeaderMenu_burger span {
    min-width: 100%;
    height: 2.5px;

    transition: all 300ms ease;

    border-radius: 8px;
    background-color: var(--bright);
  }

  .HeaderMenu_burgerBlock
    > input:checked
    + .HeaderMenu_burger
    span:first-child {
    transform: rotate(-45deg) translate(-7px, 8px);
  }

  .HeaderMenu_burgerBlock
    > input:checked
    + .HeaderMenu_burger
    span:nth-child(2) {
    opacity: 0;
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:last-child {
    transform: rotate(45deg) translate(-5px, -7px);
  }

  .HeaderMenu_linksBlockMobile {
    flex-direction: column;
    justify-content: space-between;

    width: 100vw;
    height: calc(100vh + (var(--vh-px, 0) - 100vh) - 50px);
    min-height: 512px;
    margin: 0;
  }

  .HeaderMenu_linksMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    margin: 0 16px;
  }

  .HeaderMenu_linkMobile {
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: fit-content;
  }

  .HeaderMenu_linkMobile.HeaderMenu_linkMobile__active::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 3px;
    background: #ffffff;
    border-radius: 8px;
  }

  .HeaderMenu_linkMobile:last-child {
    margin-bottom: 0;
  }

  .HeaderMenu_buttonsMobile {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 40px 0;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    padding: 0 48px;
  }

  .HeaderMenu_menu__mobileSticky {
    position: fixed;
  }

  .HeaderMenu_menu__mobileSticky.HeaderMenu_menu__mobile {
    background-color: var(--bright);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_burger span {
    background-color: var(--primary);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_logoLight path {
    fill: var(--primary);
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) and (max-height: 812px) {
  .HeaderMenu_linksBlockMobile {
    padding-bottom: 90px;
  }
}
