@media screen and (min-width: 768px) and (max-width: 1200px),
  screen and (max-width: 896px) and (min-aspect-ratio: 13/9) {
  .BenefitsSection {
    position: relative;

    box-sizing: content-box;
    height: 360px;
    /* margin: calc((100vh - 778px) / 2) 0; */
    padding: calc((100vh - 360px) / 2) 0;

    /* margin-top: 96px;
    padding: 16px;
    padding-top: 64px; */
  }

  .BenefitsSection_container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    padding: 0 40px;
  }

  .BenefitsSection_title {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;

    text-align: left;
    letter-spacing: 0.1em;
  }

  .BenefitsSection_container h2 {
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    line-height: 26px;

    text-align: left;
  }

  .BenefitsSection_slideContainer {
    width: 396px;
    min-width: 396px;
  }

  .BenefitsSection_viewSlider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 416px;
    margin-top: 0;
    margin-left: -15px;
    padding: 0 20px;
  }

  .BenefitsSection_shadow {
    box-shadow: 0 0 10px 25px var(--bright);
  }

  .BenefitsSection_slide {
    --position: 100vw;

    width: 100%;
    min-width: initial;
    padding: 0;

    -webkit-transform: translateX(var(--position));
    -moz-transform: translateX(var(--position));
    -ms-transform: translateX(var(--position));
    -o-transform: translateX(var(--position));
    transform: translateX(var(--position));
  }

  .BenefitsSection_slide__shown {
    --position: 0;
  }

  .BenefitsSection_slideImg {
    width: 32px;
    height: 32px;
  }

  .BenefitsSection_dots {
    display: flex;
    justify-content: flex-start;

    margin-top: 18px;
  }

  .BenefitsSection_dot {
    margin: 0 5px;
  }

  .BenefitsSection_slide span {
    text-align: left;
  }

  .BenefitsSection_slideContainer .__subtitle-1 {
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) and (max-height: 600px) {
  .BenefitsSection {
    padding-top: 52px;
  }
}
