@media screen and (min-width: 768px) and (max-width: 1200px),
  screen and (max-width: 896px) and (min-aspect-ratio: 13/9) {
  .EtzMobileSection {
    /* overflow: initial; */
    justify-content: flex-start;

    box-sizing: content-box;
    height: 746px;
    margin-top: 120px;
    margin-bottom: 0;
    padding: 0 16px;
    padding-bottom: 120px;
  }

  .EtzMobileSection_block {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: 100%;

    place-self: initial;
  }

  .EtzMobileSection_description {
    z-index: 50;

    min-width: initial;
  }

  .EtzMobileSection_title {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.1em;
  }

  .EtzMobileSection_subTitle {
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    line-height: 26px;

    width: 100%;
    margin: 8px 0 32px 0;

    text-align: center;
  }

  .EtzMobileSection_textContainer {
    padding: 0;
    text-align: center;
  }

  .EtzMobileSection_text {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
  }

  .EtzMobileSection_mobiles {
    /* top: -54px;
    left: 50%;
    height: 426px; */
    height: 508px;

    transform: translateX(25%);
  }

  .EtzMobileSection_img {
    /* min-height: 100%; */
    height: 622px;
  }

  .EtzMobileSection_eth {
    right: 124px;
    bottom: calc(-91px - var(--coin-position));
  }

  .EtzMobileSection_btc {
    top: calc(156px + var(--coin-position));
    left: 99px;
  }

  .EtzMobileSection_ada {
    top: calc(292px + var(--coin-position));
    left: -121px;
  }

  .EtzMobileSection_avax {
    top: calc(196px + var(--coin-position));
    left: 266px;
  }

  .EtzMobileSection_bnb {
    top: calc(51px + var(--coin-position));
    left: -109px;
  }

  .EtzMobileSection_btc {
    top: calc(59px + var(--coin-position));
    left: 102px;
  }

  .EtzMobileSection_ftm {
    top: calc(2px + var(--coin-position));
    left: -155px;
  }

  .EtzMobileSection_sol {
    top: calc(-14px + var(--coin-position));
    right: 105px;
  }

  .EtzMobileSection_usdc {
    bottom: calc(-178px - var(--coin-position));
    left: 125px;
  }

  .EtzMobileSection_pattern {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transform: initial;
  }
}
