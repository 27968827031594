@media screen and (min-width: 768px) and (max-width: 1200px),
  screen and (max-width: 896px) and (min-aspect-ratio: 13/9) {
  .ContactUsSection {
    min-height: 740px;
    margin-top: 0;
    padding: 0;
  }

  .ContactUsSection_container {
    width: 100%;
    padding: 0 16px;

    place-self: initial;
  }

  .ContactUsSection_card {
    width: 100%;
    height: initial;
    padding: 48px 32px;
  }

  .ContactUsSection_peopleImgBlock {
    display: none;
  }

  .ContactUsSection_input {
    width: 100%;
  }

  .ContactUsSection_input__left {
    margin-right: 8px;
  }

  .ContactUsSection_input__right {
    margin-left: 0;
  }

  .ContactUsSection_input__half,
  .ContactUsSection_input__3Quarters,
  .ContactUsSection_input__1Quarters {
    flex-basis: 265px;
    flex-grow: 0;

    min-width: calc(50% - 4px);
    margin-bottom: 16px;
  }

  .ContactUsSection_formBlock {
    flex-basis: initial;
    flex-grow: initial;

    max-width: initial;
  }

  .ContactUsSection_form {
    max-width: inherit;
  }

  .ContactUsSection_title {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 28px;

    margin-bottom: 28px;

    text-align: left;
  }

  .ContactUsSection_subtitle {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;

    text-align: left;
  }

  .ContactUsSection_textarea {
    width: 100%;
    height: 90px;
  }

  .ContactUsSection_formFooter {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    margin-top: 32px;
  }

  .ContactUsSection_formButtonBlock {
    align-self: center;
  }

  .ContactUsSection_submit {
    width: 180px;
    height: 56px;
  }

  .Button_text {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
  }

  .ContactUsSection_container {
    width: 100%;
    padding: 48px;

    place-self: initial;
  }
}
